var DEFAULTS = {
	Chrome: 57, // Includes Chrome for mobile devices
	Edge: 39,
	Safari: 10,
	"Mobile Safari": 10,
	Opera: 50,
	Firefox: 50,
	Vivaldi: 1,
	IE: false
}

var UPDATE_URL = {
	Chrome: 'https://www.google.com/chrome/',
	Edge: 'https://www.microsoft.com/en-us/edge',
	Safari: 'https://support.apple.com/en-us/HT204416',
	Opera: 'https://www.opera.com/download',
	Firefox: 'https://www.mozilla.org/cs/firefox/new/',
	Vivaldi: 'https://vivaldi.com/cs/download/',
	IE: 'https://www.microsoft.com/en-us/edge'
}

var EDGEHTML_VS_EDGE_VERSIONS = {
	12: 0.1,
	13: 21,
	14: 31,
	15: 39,
	16: 41,
	17: 42,
	18: 44
}

var updateDefaults = function (defaults, updatedValues) {
	for (var key in updatedValues) {
		defaults[key] = updatedValues[key]
	}

	return defaults
}

module.exports = function (parsedUserAgent, options) {
	// Set default options
	var browserSupport = options.browserSupport ? updateDefaults(DEFAULTS, options.browserSupport) : DEFAULTS
	var requiredCssProperty = options.requiredCssProperty || false

	var browserName = parsedUserAgent.browser.name;

	var isAndroidButNotChrome
	if (options.requireChromeOnAndroid) {
		isAndroidButNotChrome = parsedUserAgent.os.name === "Android" && parsedUserAgent.browser.name !== "Chrome"
	}	
	
	var parseMinorVersion = function (version) {
		return version.replace(/[^\d.]/g, '').split(".")[1];
	}

	var isBrowserUnsupported = function () {
		var isUnsupported = false
		if (!(browserName in browserSupport)) {
			if (!options.isUnknownBrowserOK) {
				isUnsupported = true
			}
		} else if (!browserSupport[browserName]) {
			isUnsupported = true
		}
		return isUnsupported;
	}

	var updateUrl = (browserName in UPDATE_URL) ? UPDATE_URL[browserName] : null

	var isBrowserUnsupportedResult = isBrowserUnsupported();

	var isBrowserOutOfDate = function () {
		var browserVersion = parsedUserAgent.browser.version;
		var browserMajorVersion = parsedUserAgent.browser.major;
		var osName = parsedUserAgent.os.name;
		var osVersion = parsedUserAgent.os.version;

		// Edge legacy needed a version mapping, Edge on Chromium doesn't
		if (browserName === "Edge" && browserMajorVersion <= 18) {
			browserMajorVersion = EDGEHTML_VS_EDGE_VERSIONS[browserMajorVersion];
		}

		// Firefox Mobile on iOS is essentially Mobile Safari so needs to be handled that way
		// See: https://github.com/mikemaccana/outdated-browser-rework/issues/98#issuecomment-597721173
		if (browserName === 'Firefox' && osName === 'iOS') {
			browserName = 'Mobile Safari';
			browserVersion = osVersion;
			browserMajorVersion = osVersion.substring(0, osVersion.indexOf('.'));
		}

		var isOutOfDate = false
		if (isBrowserUnsupportedResult) {
			isOutOfDate = true;
		} else if (browserName in browserSupport) {
			var minVersion = browserSupport[browserName]
			if (typeof minVersion == 'object') {
				var minMajorVersion = minVersion.major
				var minMinorVersion = minVersion.minor

				if (browserMajorVersion < minMajorVersion) {
					isOutOfDate = true
				} else if (browserMajorVersion == minMajorVersion) {
					var browserMinorVersion = parseMinorVersion(browserVersion)

					if (browserMinorVersion < minMinorVersion) {
						isOutOfDate = true
					}
				}
			} else if (browserMajorVersion < minVersion) {
				isOutOfDate = true
			}
		}
		return isOutOfDate
	}

	// Returns true if a browser supports a css3 property
	var isPropertySupported = function (property) {
		if (!property) {
			return true
		}
		var div = document.createElement("div")
		var vendorPrefixes = ["khtml", "ms", "o", "moz", "webkit"]
		var count = vendorPrefixes.length

		// Note: HTMLElement.style.hasOwnProperty seems broken in Edge
		if (property in div.style) {
			return true
		}

		property = property.replace(/^[a-z]/, function (val) {
			return val.toUpperCase()
		})

		while (count--) {
			var prefixedProperty = vendorPrefixes[count] + property
			// See comment re: HTMLElement.style.hasOwnProperty above
			if (prefixedProperty in div.style) {
				return true
			}
		}
		return false
	}

	// Return results
	return {
		isAndroidButNotChrome: isAndroidButNotChrome,
		isBrowserOutOfDate: isBrowserOutOfDate(),
		isBrowserUnsupported: isBrowserUnsupportedResult,
		isPropertySupported: isPropertySupported(requiredCssProperty),
		updateUrl: updateUrl
	};
}
